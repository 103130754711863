@media print {
  body {
    // hidden elements
    .SlideContainer,
    .HorizontalLine,
    .ProductButtonsContainer,
    .ProductCtasContainer,
    .ModalContainer,
    .ModalOverlay,
    .ModalWrapper,
    .ModalClose,
    .ModalContent,
    .ProductRelatedProductsSlider,
    .FavoritesCtaContainer,
    .FooterBanner,
    .FooterContainer
    {
      display: none;
    }

    // header
    .HeaderContainer {
      position: absolute;
      top: 0;
      left: 0;
      
      & > *:not(.LogoContainer) {
        display: none;
      }
    }

    // fade-in sections
    .fade-in-section {
      opacity: 1 !important;
      visibility: visible !important;
    }

    // title area
    .TitleAreaContainer {
      padding: 50px 20px 20px;
    }

    // favorites grid
    .ProductGridWrapper {
      flex-wrap: wrap;

      .ProductGridItem {
        flex: 0 0 23%;
        margin-bottom: -10%;
        height: auto;
        -webkit-print-color-adjust: exact;

        .ProductImage {
          width: 250px;
          height: 250px;
          padding: 0;
          margin: 0 auto;
          -webkit-print-color-adjust: exact;
        }
      }
    }

    /* // product gallery */
    .ProductGalleryContainer .ProductGalleryCurrentSelectionContainer {
        width: 250px;
        height: 250px;
        padding: 0;
        margin: 0 auto;
        -webkit-print-color-adjust: exact;
    }
  }
}
