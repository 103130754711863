/**
 * Font imports
 */

/* Assistant Font */
@font-face {
  font-family: 'Assistant';
  src: url('../assets/fonts/Assistant/Assistant-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Assistant';
  src: url('../assets/fonts/Assistant/Assistant-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Assistant';
  src: url('../assets/fonts/Assistant/Assistant-Bold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

/* FjallaOne Font */
@font-face {
  font-family: 'Fjalla One';
  src: url('../assets/fonts/Fjalla_One/FjallaOne-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/* OpenSans Font */
@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/Open_Sans/OpenSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/Open_Sans/OpenSans-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
